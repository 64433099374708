:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em;
 /* must NOT be a percentage value */
    --vertical-margin: 1em;
  /* must NOT be a percentage value */
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: 'Open Sans';
    --alternative-font-family: Roboto, Helvetica, Arial, sans-serif;
    /* change settings for headings */
    --header-bg-color: #e3000f;
    --header-padding: 0;
    /* change colors for topmenu */
    --topmenu-bg: var(--link-color);
    --topmenu-color: white;
    --topmenu-li-a-padding: 8px 25px;
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /*Colors & Backgrounds*/
    --text-color: #444;
    --heading-color: var(--link-color);
    --link-color: #222;
    --link-hover-color: #000;
    --alternative-color: #e3000f;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    
@import "/css/basic_styling.css";

@import "/css/flex_grid.v1.1.css";

@import "/css/slick.css";

@import "/css/slick-theme.css";

@import "/css/menu.v1.2.css";

@import "/css/magnific-popup.css";

@import "/css/pikaday.css";

@import "/fonts/Open Sans/all.css";

@import "/fonts/Roboto/all.css";
/*@import "/cssmodules/animate.css";*/

/* General bg-colors
---------------------------------------------------------*/

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color: white;
}

.lightgray {
    background-color: #eee;
}

.darkgray {
    background-color: #222;
}

.altcolor {
    background-color: var(--alternative-color);
}

.topout {
    padding-top: 0 !important;
}

/* Header
---------------------------------------------------------*/

.topbanner {
    border-top: black solid 2px;
}

.topbanner p {
    margin:0;
    display:inline;
}

.topbanner>div {
    background: #373737;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: #373737 solid 1px;
    border-right: #373737 solid 1px;
    border-bottom: #373737 solid 1px;
    margin-right: 2em;
    padding: 0.5em;
    color: white;
}

.topbanner>div a {
    color: white;
    text-decoration: none;
}

.topbanner>div a svg.fa-icon {
    fill: white;
}

.topout {
    padding: 1em 0;
}

.topmenu {
    padding: 0.5em 0;
}

.topcontact a {
    text-decoration: none;
    padding: 0 1em;
    font-size: 0.9rem;
}

.topcontact a span {
    display: inline;
    border-radius: 50%;
    background: black;
    padding: 7px;
    max-height: 30px;
    height: 30px;
}

.topcontact a .mail {
    background: #2078A0;
}

.topcontact a .phone {
    background: #00A79D;
}

.topcontact a .facebook {
    background: #39579A;
}

.topcontact svg.fa-icon {
    fill: white;
}

header {
    background: #1A1A18;
    text-transform: none;
    padding: var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    z-index: 2;
    border-bottom:rgba(49, 49, 49, 0.68) solid 6px
}

.topcontact .container {
    padding: 0.5em;
}

.resize-header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
    transition: 0.5s ease;
}

.resize-header .logo img {
    max-width: 100%;
    animation: reduceLogo 0.1s forwards;
    transition: 0.5s ease;
}

@-webkit-keyframes reduceLogo {
    from {
        max-width: 100%;
    }

    to {
        max-width: 70%;
    }
}

@keyframes reduceLogo {
    from {
        max-width: 100%;
    }

    to {
        max-width: 60%;
    }
}

header .container {
    padding: 0 0.5em;
}

header a {
    color: #fff;
}

.menu li {
    margin: 0;
}

.menu li a {
    padding: var(--topmenu-li-a-padding);
    color: #b0b0b0;
    font-family: var(--alternative-font-family);
    margin-right: 1px;
    text-transform: uppercase;
    font-weight: 500;
}

.menu li a:hover, .menu .active a {
    background: #1A1A18;
    color: #fff;
}

.menu .dropdown ul {
    background: #1A1A18;
    z-index: 100;
    border-bottom: var(--alternative-color) solid 2px;
}

.menu li:hover ul {
    margin-left: 0;
}

.menu .dropdown li a {
    padding: var(--topmenu-li-a-padding);
    color: #000;
    border-right: none;
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    background: #EEEEEE;
    color: #9B151D;
}

.menu .sub-menu-items li a {
    margin: 0;
    transform: none !important;
}

.subtopmenu ul {
    list-style: none;
    padding: 0;
}

.subtopmenu ul li {
    display: block;
    border-bottom: #e4dbcb dotted 1px;
}

.subtopmenu ul li a {
    display: block;
    text-decoration: none;
    padding: 0.4em;
}

.subtopmenu ul .active a, .subtopmenu ul li a:hover {
    color: #9a141b;
}

.logo {
    padding: 0;
}

.logo a {
    font-size: 0;
}

.menu .button {
    background: var(--alternative-color);
    border: #ca000d solid 1px;
    box-shadow: 0 0 0 1px #fff;
}

.menu .button:hover {
    background: var(--alternative-color);
    border: #ca000d solid 1px;
}

.menu .button a {
    color: white;
}

/* bildspel
---------------------------------------------------------*/

.slick {
    position: relative;
    margin-bottom: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}

.slick, .slick-slide, .slick-substitute > div {
    height: 50vh;
}

.slick-slide, .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

.slick-slider {
    margin: 0 auto;
    position: relative;
}

.slick-slide, .slick-substitute > div {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.slick-title {
    text-align: center;
    color: white;
    font-size: calc(1 * var(--scale-factor-s)rem);
}

.slick-title .small {
    max-width: 800px;
    width:100%;
    margin: 0 auto;
}

.slick-title h2 {
    display: block;
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    color: white;
    font-weight: 700;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom: 0.5em;
}

.slick-prev, .slick-next {
    width: auto;
    height: auto;
}

.slick-next {
    right: 50px;
    z-index: 100;
}

.slick-prev {
    left: 50px;
    z-index: 100;
}

.slick-next, .slick-prev {
    opacity: 1;
}

.slick-next:hover, .slick-prev:hover {
    border: none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
    background: rgba(0,0,0,0.7);
    padding: 1.5em;
    font-family: Roboto;
    line-height: normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background: white;
    color: black;
}

.slick-next:before {
    content: "»";
}

.slick-prev:before {
    content: "«";
}

.slick-dots {
    bottom: 0px;
}

.slick-dots li button:before {
    font-size: 0rem;
    opacity: 1.0;
    color: var(--alternative-color);
    width: 40px;
    height: 8px;
}

.slick-dots li.slick-active button:before {
    background: var(--alternative-color);
    display: block;
}

.slick-dots li {
    border: var(--alternative-color) solid 1px;
    border-radius: 0%;
    margin: 0 2px;
    width: 40px;
    height: 8px;
}

.slider-btn {
    margin-top: 1em;
}


.contactform .boltforms-row label{
    display:none;
}

.bokning .contactform .boltforms-row label{
    display:block;
}

/* Content-startpage
---------------------------------------------------------*/

.intro-txt {
    padding: 3em 0;
}

.block-2 {
    padding: 3em 0;
}

.startbanner {
    text-align: center;
}

.startbanner h2 {
    margin: 0;
    display: block;
}

.block-2 .container .inner-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    border-bottom: #eee solid 1px;
}

.intro-content h2 {
    color: black;
    text-transform: uppercase;
    margin: 0;
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

.startbox-wrapper {
    box-shadow: 0 8px 0 1px #eee;
    word-wrap: break-word
}

.startbox-wrapper .pop-img {
    border-bottom: var(--alternative-color) solid 6px;
}

.startbox-wrapper .pop-img img {
    display: block;
}

.startbox-wrapper .startbox-inner {
    padding: 1em;
}

/* zoomboxar */

.boxwrapper {
    min-height: 35vh;
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    box-shadow: 0 0 0 4px #fff;
    margin-bottom: 0.4em;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 3;
    font-weight: 400;
}

.boxwrapper h2 span {
    display: block;
}

.boxwrapper h2 span:first-child {
    text-transform: none;
    font-size: 1.3rem;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .65s ease-in 0s;
    z-index: 2;
}

.zoombox .boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .65s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index: 101;
    opacity: 0;
    text-align: center;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    color: white;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity: 1;
}

.zoombox-body h2 {
    font-size: calc(0.8 * var(--scale-factor) * var(--scale-factor)rem);
    font-weight: 300;
}
    


/* block-3 */
.block-3 {
    padding: 1.5em 0;
}

/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom: 1.5em;
}

.listing-item p:first-of-type {
    padding-top: 0;
    margin-top: 0;
}

.listing-item h2 {
    margin-bottom: 0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color: white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/

.content.margin > h1, .content.margin > .art-img {
    margin-left: 0;
}

.content h1 {
    margin-bottom: 0;
}

.art-content p:first-of-type {
    margin-top: 0;
}

.top-img {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 20vh;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    filter: grayscale(100%);
}

.tjanst-item .slick-substitute >div, .banner-img {
    min-height: 25vh;
    filter: grayscale(100%);
}

#slideout {
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    color: #333;
    position: fixed;
    top: 50%;
    z-index: 10000;
    transition: transform .3s;
    transform: translateX(-100%) translateY(-50%);
    z-index: 100;
}

#slideout.on {
    transform: translateX(-0%) translateY(-50%);
}

.show-slide {
    position: absolute;
    top: 50%;
    right: 0px;
    z-index: 10000;
    transform: rotate(90deg) translateY(-233%) translateX(-21%);
    background: #39579A;
    color: white;
    border: none;
    border-radius: 0px;
    padding: 10px 30px;
}

.calendar-inner {
    padding: 2em;
}

.dots ul {
    list-style: none;
    margin: 0;
    padding:0;
}

.dots ul li {
    padding-left: 1.7em;
    margin: 1em 0;
    font-style: italic;
}

.dots ul li:before {
    background: red;
    color: white;
    content: "\2714";
    display: inline-block;
    width: 1.7em;
 /* same as padding-left set on li */
    margin-left: -1.7em;
 /* same as padding-left set on li */
    margin-right: 1em;
    text-align: center;
    font-style: normal;
}

.erbjuder .pop-img {
    text-align: center;
    background: #E3000F;
    min-height: 150px;
}

.erbjuder .pop-img svg.fa-icon {
    display: block;
    text-align: center;
    margin: 0 auto;
    width: 72px;
    height: 72px;
    fill: white;
}

.erbjuder .startbox-inner a {
    text-decoration: none;
}

.mfp-iframe-scaler iframe {
    background: white;
}

.top-img h1 {
    color: white;
}

.contentwrapper {
    padding: 2em 0;
}

.tjanster-list {
    padding: 0 0 3em 0;
}


/* Contact
---------------------------------------------------------*/


/*.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}*/

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 .contactinfo {
    padding:1.5em 0 0 0;
}

.contact-block-2 h2 {
    border-bottom:#eee solid 1px;
}

.contact-block-l h1 {
    font-weight:300;
}

.contact-block-2 .contactinfo p {
    margin: 0;
}

.contact-block-2>div a {
    text-decoration: none;
}

.art-img {
    margin-right: 0;
}

figure {
    margin: 0;
}

.other-item h2 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    text-transform: uppercase;
}

.other-item-inner {
    padding: 1.5em;
}

.contactform {
    color: white;
}

.contactform h2 {
    color: white;
}

.contactform .boltform, .contactform >div {
    width: 100%;
}

.contactform .boltform input[type=text] {
    border-radius: 0;
    padding: 0.5em;
}

.homepage .contactform .boltforms-row {
    display: inline-block;
}

.homepage .contactform .boltforms-btn-send-row {
    display: inline-block;
    vertical-align:top;
}

.homepage .contactform .boltform br {
    display: none;
}

.homepage .boltforms-mail-row {
    max-width: 80%;
    width: 100%;
}

.contactform .boltform ::-webkit-input-placeholder {
    color: black;
}

.contactform .boltform input[type=email] {
    border-radius: 0;
    padding: 0.5em;
}

.homepage .contactform .boltform button[type=submit] {
    display: block;
    width: 100%;
    background: var(--alternative-color);
}

.homepage .contactform .boltform button[type=submit]:hover {
    background: black;
    border: black solid 1px;
}

.contacttext p:first-of-type {
    margin: 0;
}

.block-5 {
    padding: 3em 0;
    border-top: #ccc dotted 1px;
}

.block-5 h2, .block-5 {
    color: black;
}

.homepage .contactform > div {
    max-width:600px;
    width:100%;
    margin:0 auto;
}

.homepage .contacttext  {
    color:white;
    margin-bottom:0.5em;
    text-align:center;
}

.homepage .contacttext h2 {
    color:white;
    font-size: calc(0.75 * var(--scale-factor) * var(--scale-factor)rem);
    font-weight:300;

}
    

/* Footer
---------------------------------------------------------*/

footer {
    color: #000;
}

footer .container {
    padding: 1.5em 0;
}

footer h4 {
    color: #222;
    font-size: 1.6rem;
    letter-spacing: 0;
}

footer h5 {
    color: #222;
    font-size: 1.1rem;
    letter-spacing: 0;
}

footer a {
    color: #000;
    text-decoration: none;
}

footer .social svg.fa-icon {
    fill: #5f5f5f;
    width: 28px;
    height: 28px;
}

footer svg.fa-icon:hover {
    fill: var(--alternative-color);
}

footer .button {
    margin: 0;
    background: #E3000F;
    border: #E3000F solid 1px;
    border-radius: 0px;
    text-align: left;
}

footer .social-bottom {
    margin-top: 1.5em;
}

footer p {
    margin: 0;
}

/*Google maps*/
.map-canvas {
    height: 45vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background: #100F0F;
    border-top: #151414 solid 1px;
    padding: 0.5em 0;
}

.created-by .container, .intenditfooter {
    padding: 0;
    margin: 0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a {
    color: #565656;
}

.intenditfooter >* {
    margin: var(--margin);
}

/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%;
 /* IE fix */;
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin: 0;
}


.popuplinks .button {
    margin-bottom:0.5em;
}

/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height: 120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color: #222;
}

button[type=submit], input[type=submit], .button {
    margin: 0;
    background: var(--link-color);
    border: var(--link-color) solid 1px;
    color: white;
}

button[type=submit]:hover, input[type=submit]:hover, .button:hover {
    background: var(--link-hover-color);
    border: var(--link-hover-color) solid 1px;
    color: white;
}

img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
    margin: 0 auto;
}

.container.lgr {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height: 100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    color: var(--heading-color);
    margin: 0 0 0.3em 0;
}

h1 {
    font-size: calc(0.95 * var(--scale-factor) * var(--scale-factor)rem);
    line-height:normal;
}

h2 {
    font-size: calc(.8 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

a p {
    margin:0;
}

.markerLabels {
    overflow: visible !important;
}

.fa-map-marker {
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

.book {
    background: #E3000F;
    border: #E3000F solid 1px;
    color: white;
    filter: none !important;
}


/* Media Queries
---------------------------------------------------------*/
@media (min-width: var(--breakpoint-menu)) {
    #boka_datum > * {
        display: inline-block;
    }

    #boka_datum  select {
        margin: 0 0.5em 1em 0.5em;
    }

    #boka_datum  select:first-child {
        margin-left: 0;
    }
}

@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .menu {
        background: #1A1A18 !important;
        box-shadow: 0 4px 2px -2px gray;
    }
}

@media (max-width: var(--breakpoint-s)) {
    footer .container {
        text-align: center;
    }
}

@media (max-width: var(--breakpoint-xs)) {
    .slick, .slick-slide, .slick-substitute>div {
        min-height:50vh;
        align-items:flex-start;
    }
    .slick-title {
        padding:1em 2em;
    }
    .slick-title h2 {
        letter-spacing:-1px;
        font-size: calc(1.3 * var(--scale-factor)rem);
    }
    .assistans .inner-content {
        padding:0;
    }
    .block-2 {
        padding:1em 0;
    }
    .pop-content.small {
        padding:0 1.5em;
    }
    .contentwrapper, .tjanster-list, .intro-txt, .block-3, .assistans , .block-5{
        padding:0 1.5em;
    }
    header, footer {
        padding:1.5em 0 0 0;
    }
}

.menu li a {
    position: relative;
}

.menu li a:before {
    content: "";
    position: absolute;
    bottom: 0;
    background: #E3000F;
    height: 4px;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transition: transform 0.3s ease-out;
}

.menu li a:hover:before {
    transform: scale(1, 1);
}

.assistans {
    display: flex;
    align-items: center;
}

.assistans .inner-content {
    max-width: 600px;
    width: 100%;
    margin: 0;
    padding: 2em;
}

.assistans .inner-content h2 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    line-height: normal;
}

.readmore-btns {
    margin-top: 2.5em;
}

.container.small {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.container.small .content {
    text-align: center;
}

.bokning .contactform {
    color: black;
}

.popuplinks {
    margin-bottom: 1em;
}

/*magnific styling*/ 

button.mfp-close {
    font-size: 4em;
    margin: 0.2em 0 0 0;
    opacity: 0.9;
}

.mfp-close-btn-in .mfp-close {
    color:white;
}

.popup-topbar {
    background:var(--alternative-color);
    padding:1em;
}

.popup-topbar h2 {
    color:white;
    margin:0;
    padding:0;
}

.pop-content.small {
    max-width:800px;
    width:100%;
    margin:2em auto;
}

.mfp-ajax-holder .mfp-content {
    background: #FFF;
    text-align: left;
    max-width: 990px;
    margin: 40px auto;
    position: relative;
}

.flex.layout {
    align-items:flex-start;
}

.tjanst .inner {
    padding: 0.5em;
}

.tjanst .inner ul {
    padding:0;
}

.tjansterlist h1 {
    display:block; 
    font-weight:300;
    margin-left:1.5%;
    margin-bottom:1em;
}

.popuplinks a {
    text-align:center;
}

.popuplinks a {
    padding:1.5em 5em;
    font-size:1.3rem;
    background:#E2000E;
    background-image:linear-gradient(0deg,rgb(226, 0, 14),rgb(165, 18, 27));
    border:#E2000E solid 1px;
    text-shadow: -1px 0px 0px #fff;
    color:white;
    text-decoration:none;
    transition: 0.5s ease;
}

.popuplinks a:hover {
    background-image:linear-gradient(0deg,#000,#3e3c3c);
    border:#000 solid 1px;
}

.zoombox-body h3, footer h4, footer, .startbanner h2, .kontakt, .kontakt h2 {
    font-weight:300;
}

.tjanst-list h2 {
    font-size: calc(0.8 * var(--scale-factor) * var(--scale-factor)rem);
    font-weight: 300;
}


.tjant-content p:first-of-type, .tjant-content h2 {
    margin-top:0;
}

.contentwrapper.tjansterlist {
    padding-top:0;
}
